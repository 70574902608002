/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://iatuy3bu4zgxvmf7k6jmxquhya.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lozepcbwunfy3dqbsf4vi7z754",
    "aws_cognito_identity_pool_id": "us-west-2:dc66d1b3-3b3b-45af-b941-0be9598a3a37",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_c7lEWbdgP",
    "aws_user_pools_web_client_id": "3rvklre1mf10iflir8kkh8quhi",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "zeusity-storage-c21e9f53201353-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
